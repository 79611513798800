.logo {
  font-size: 28px;
}

.logo span {
  color: var(--main-color);
}

/* 768px - 992px */
@media screen and (max-width: 991px) {
  .logo {
    font-size: 18px;
  }

}

/* 320px - 576px */
@media screen and (max-width: 576px) {
  .logo {
    font-size: 28px;
  }
}