.сard {
  background: rgba(0, 15, 26, 0.54);
  padding: 30px 40px 38px 50px;
  border-radius: 20px;
}

.сity {
  text-align: left;
  font-weight: bold;
  text-transform: none;
  font-size: 45px;
}
.сity.сityError {
  color: red;
  font-size: 25px;
}

.body {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.degrees {
  width: 30%;
  text-align: left;
  font-size: 66px;
}

.degrees span {
  font-weight: bold;
}

.info {
  text-align: left;
  width: 70%;
  font-size: 36px;
}

.wet {

}

.wind {

}

/* 768px - 992px */
@media screen and (max-width: 991px) {
  .сard {
    padding: 15px 25px;
  }
  .сity {
    font-size: 35px;
  }
  .info {
    font-size: 26px;
  }
}

/* 576px - 768px */
@media screen and (max-width: 767px) {
  .body {
    flex-direction: column;
    margin-top: 0;
  }
  .сity {
    text-align: center;
    font-size: 30px;
  }
  .degrees {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 56px;
  }
  .info {
    width: 100%;
    font-size: 20px;
  }
  .wet {
    display: flex;
    justify-content: center;
  }
  .wind {
    display: flex;
    justify-content: center;
  }
}

/* 320px - 576px */
@media screen and (max-width: 576px) {


}