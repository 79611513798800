.location {
  height: 100%;
}
.add {
  border-radius: 20px 20px 0 0;
  background: var(--main-color);
  display: flex;
  align-items: center;
  padding: 30px;
  transition: var(--transition);
  cursor: pointer;
}

.add:hover {
  background: #e17777;
}

.add:hover .plus {
  transform: rotate(360deg);
}

.add:hover .plus {
  color: #e17777;
}

.plus {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  background: #fff;
  border-radius: 100%;
  color: var(--main-color);
  font-size: 35px;
  font-weight: bold;
  transition: var(--transition);
}

.plus span {
  width: 20px;
  height: 4px;
  background: var(--main-color);
}

.plus span:after {
  content: '';
  display: block;
  width: 4px;
  height: 20px;
  margin: -8px auto 0 auto;
  background: var(--main-color);

}

.titleWrapper {
  padding-left: 20px;
  text-align: left;
}

.title {
  font-size: 30px;
}

.text {
  font-size: 19px;
}

/*Responsive*/
/* 992px - 1200px */
@media screen and (max-width: 1199px) {
}

/* 768px - 992px */
@media screen and (max-width: 991px) {
  .add {
    padding: 15px;
  }
  .title {
    font-size: 18px;
  }
  .text {
    font-size: 14px;
  }
}

/* 576px - 768px */
@media screen and (max-width: 767px) {
}

/* 320px - 576px */
@media screen and (max-width: 576px) {
}