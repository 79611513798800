/* Variables-START */

:root {
  --main-color: #41d9ca;
  --transition: all 0.25s ease-out;
}
/* Variables-END */

/* Basic-START */
body *,
body *:before,
body *:after {
  box-sizing: border-box;
}
html,
body {
  padding: 0;
  margin: 0;
  font-size: 20px;
}
div,
textarea,
p,
input,
form,
span,
ul,
li {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}
address,
blockquote,
figure,
figcaption,
main,
details,
fieldset {
  display: block;
  border: 0;
  font-style: normal;
  margin: 0;
  padding: 0;
}
a {
  box-sizing: border-box;
  color: inherit;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style-type: none;
  border: none;
  outline: none !important;
  transition: all 0.25s ease-out;
}
a:hover,
a:active {
  text-decoration: none;
  outline: none;
  transition: all 0.25s ease-out;
  color: none !important;
}
button,
input {
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  cursor: pointer;
  outline: none;
  list-style-type: none;
  transition: all 0.5s ease;
}
button:active,
input:active,
button:hover,
input:hover,
button:focus,
input:focus {
  outline: none;
  transition: all 0.5s ease;
}
img {
  display: block;
  max-width: 100%;
  height: auto;
}
input,
li {
  list-style-type: none;
}
/* Basic-END */

.App {
  text-align: center;
  color: #fff;
}

.container {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main {
  background: rgb(1, 53, 110);
  background: url(./img/home.jpg) no-repeat top center;
  min-height: 100vh;
  height: auto;
}

