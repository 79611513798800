.wrapper {
  display: flex;
  width: 100%;
}

.content {
  display: inline-block;
  width: 65%;
  padding: 100px 15px 0px 0px;
}

.sidebar {
  display: inline-block;
  width: 35%;
  padding: 0 0 0 15px;
  height: 500px;
}

/* 576px - 768px */
@media screen and (max-width: 767px) {
  .wrapper {
    flex-direction: column;
  }
  .content {
    margin: 40px auto 0 auto;
    padding: 0;
    width: 90%;
  }
  .sidebar {
    width: 90%;
    margin: 0 auto;
    padding: 0;
  }
}