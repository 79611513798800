.header {
  padding: 15px 0;
  background: rgb(50, 50, 50);
}
.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.login {
  color: var(--main-color);
}
.btn {
  display: inline-block;
  position: relative;
  z-index: 0;
  padding: 5px 10px;
  color: white;
  font-size: 20px;
  margin-left: 10px;
}
.btn::before,
.btn::after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 100%;
  transform: translate(-50%, 0);
  transition: all 0.5s ease-out;
}

.btn::before {
  border-top: 1px solid var(--main-color);
  border-bottom: 1px solid var(--main-color);
}

.btn::after {
  top: 50%;
  left: 0;
  width: 100%;
  height: 0;
  border-left: 1px solid var(--main-color);
  border-right: 1px solid var(--main-color);
  transform: translate(0, -50%);
  transition: all 0.25s ease-out;
}

.btn:hover {
  color: var(--main-color);
}

.btn:hover:before {
  width: 100%;
}

.btn:hover:after {
  height: 100%;
}

/* 576px - 768px */
@media screen and (max-width: 767px) {
  .wrapper {
    flex-direction: column;
  }
}