.wrapper {
  height: 100%;
}
.searchWrapper {
  padding-top: 30px;
}

.locationWrapper {
  margin-top: 40px;
}

/* 576px - 768px */
@media screen and (max-width: 767px) {
  .wrapper {
    width: 100%;
    margin: 0 auto;
  }
}