.list {
  padding: 20px 37px;
  max-height: 350px;
  color: #000;
  background: #fff;
  border-radius: 0 0 20px 20px;
  overflow: auto;
}

.clearAll {
  font-size: 12px;
  justify-content: flex-end;
  display: flex;
  padding-right: 11px;
  margin-bottom: 10px;
  transition: var(--transition);
  cursor: pointer;
}

.clearAll:hover {
  color: #B4B4B4;
  transition: var(--transition);
}

.list li {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 0;

  border-bottom: 1px solid #f3f3f3;
  cursor: pointer;
}

.list li:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;

  width: 0;
  height: 100%;

  background: #e17777;
  transition: var(--transition);

}

.list li:hover:after {
  width: 90%;
}

.list li:hover .name {
  color: #fff;
}

.list li:hover .arrow {
  fill: #fff;
}

.townWrapp {
  display: flex;
  width: 90%;
}

.name,
.arrow {
  position: relative;
  z-index: 1;
}

.name {
  margin-left: 10px;
  transition: var(--transition);
  width: 100%;
  text-align: left;
}

.arrow {
  top: -1px;

  display: inline-block;
  width: 12px;
}

.arrow svg {
  width: 100%;
  height: auto;
  transition: var(--transition);
}

.close {
  width: 10%;
  font-size: 12px;
  transition: var(--transition)
}

.close:hover {
  color: #B4B4B4;
  transition: var(--transition)
}

/* 576px - 768px */
@media screen and (max-width: 767px) {
  .list {
    max-height: 200px;
  }
}